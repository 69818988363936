<template>
  <b-card
      class="p-2"
      no-body
      v-if="employeeId"
  >
  <b-row>
    <b-col cols="12">
      <div class="d-flex w-100" >
        <b-link
            :to="{path:`/hr/employees/view/${this.employeeId}`}"
            class="font-weight-bold d-flex text-nowrap mr-05"
            target="_blank"


        >
        <b-avatar
            :src="employee.ImageUrl"
            :text="(employee.FirstName ? employee.FirstName.charAt(0) : '') + (employee.LastName ? employee.LastName.charAt(0) : '')"
            class="badge-light-danger mr-1 cursor-pointer"
            size="2rem"

        >
        </b-avatar>
        <div class="flex-grow-1">

          <h3 class="cursor-pointer"
          >{{ employee.Label }}</h3>

        </div>
        </b-link>
      </div>
    </b-col>
  </b-row>

  <b-row>
    <b-col cols="12">
      <div class="info-item mt-50">
        <feather-icon
            icon="PhoneIcon"
            size="16"
        />
        <span class="ml-05">{{ employee.Phone || i18nT(`Not available`) }}</span>
      </div>
      <div class="info-item mt-50">
        <feather-icon
            icon="MailIcon"
            size="16"
        />
        <span class="ml-05">
            <b-link
                :href="'mailto:'+employee.Email"
                class="position-relative"
            >{{ employee.Email }}</b-link>
        </span>
      </div>
    </b-col>
  </b-row>
</b-card>

</template>
<script>
import {
  BAvatar,
  BCard,
  BCol,
  BLink,
  VBTooltip,
  BRow
}
  from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BAvatar,
    BLink,
    BRow,
    BCol
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  props: {
    employeeId: {
      type: Number,
      default: null
    }
  },
  data(){
    return {
      employee: {
      }
    }
  },
  created(){
    if(this.employeeId){
      this.$http.get(`employees?id=${this.employeeId}`).then(({data}) => {
        this.employee = data.data.employee;
      });
    }

  },
  watch:{
    employeeId(val){
      if(val){
        this.$http.get(`employees?id=${val}`).then(({data}) => {
          this.employee = data.data.employee;
        });
      }

      },
  },
}

</script>


<style scoped lang="scss">

</style>