<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <div>
                    <b-row>
                        <b-col cols="12">
                            <h1 class="float-left">{{ i18nT(`Timesheets`) }}</h1>
                            <b-button
                                variant="primary"
                                v-b-modal.modal-request
                                class="float-right"
                                v-if="iCan(`timesheets`, `write`)"
                            >
                                <feather-icon icon="PlusCircleIcon"/>
                                {{ i18nT(`Add new`) }}
                            </b-button>
                        </b-col>
                    </b-row>
                    <div class="mb-1">
            <span class="text-muted">{{ i18nT(`Showing`) }} {{ dataMeta.from }} {{ i18nT(`to`) }}
              {{ dataMeta.to }} {{ i18nT(`of`) }} {{ dataMeta.of }}
              {{ i18nT(`entries`) }}</span>
                    </div>
                    <b-row>
                        <b-col
                            cols="12"
                            md="3"
                            v-if="controlVisible"
                        >
                            <b-form-group :label="i18nT(`Employee`)">
                                <v-select
                                    v-model="employee"
                                    :options="employeeFilterOptions"
                                    :reduce="(e) => (e.value == '' ? '' : parseInt(e.value))"
                                    label="text"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="3"
                            v-if="controlVisible"
                        >
                            <b-form-group :label="i18nT(`From date`)">
                                <date-picker
                                    v-model="from"
                                    :placeholder="i18nT(`Date from`)"
                                    reset-button
                                    :max="to"
                                    :start-weekday="1"
                                    :locale="`en-UK`"
                                    :date-format-options="{
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit',
                                        weekday: 'short',
                                      }"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col
                            cols="12"
                            md="3"
                            v-if="controlVisible"
                        >
                            <b-form-group :label="i18nT(`To date`)">
                                <date-picker
                                    reset-button
                                    v-model="to"
                                    :placeholder="i18nT(`To date`)"
                                    :min="from"
                                    :start-weekday="1"
                                    :locale="`en-UK`"
                                    :date-format-options="{
                                        year: 'numeric',
                                        month: 'short',
                                        day: '2-digit',
                                        weekday: 'short',
                                      }"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col
                            cols="12"
                            md="3"
                            v-if="controlVisible"
                        >
                            <b-form-group :label="i18nT(`Status`)">
                                <v-select
                                    v-model="status"
                                    :options="approvalStatusOptions"
                                    :reduce="(e) => (e.value == '' ? '' : parseInt(e.value))"
                                    label="text"
                                    :clearable="false"
                                />
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
            </div>

            <div>
                <div class="row mx-2">
                    <div class="col-12 col-md-6 col-sm 6 mb-1 pl-0">
                        <v-select
                            v-model="perPage"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block ml-0"
                        />
                        <b-dropdown
                            text="Actions"
                            size="md"
                            class="d-inline ml-1"
                            variant="outline-secondary"
                            v-if="selectedItems.length > 0 && iCan(`timesheets`, `write`)"
                        >
                            <b-dropdown-item @click="callApprove">
                                <feather-icon icon="ThumbsUpIcon"/>
                                <span class="align-middle ml-50">{{ i18nT(`Approve`) }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="bulkReject">
                                <feather-icon
                                    icon="ThumbsDownIcon"
                                    color="red"
                                />
                                <span class="align-middle ml-50" style="color: red">
                                  {{i18nT(`Reject`)}}
                                </span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="markAsPaid(null, true)">
                                <feather-icon icon="DollarSignIcon"/>
                                <span class="align-middle ml-50">
                                  {{i18nT(`Mark as paid`)}}
                                </span>
                            </b-dropdown-item>
                        </b-dropdown>
                        <!-- <b-dropdown
                          size="md"
                          class="d-inline ml-1"
                          variant="outline-secondary"
                        >
                          <template v-slot:button-content>
                            <feather-icon icon="ExternalLinkIcon" />&nbsp;&nbsp;{{
                              i18nT(`Export`)
                            }}
                          </template>
                          <b-dropdown-item @click="onExportExcel()">
                            <feather-icon icon="CopyIcon" />
                            <span class="align-middle ml-50">{{ i18nT(`Excel`) }}</span>
                          </b-dropdown-item>
                          <b-dropdown-item @click="onExportCsv()">
                            <feather-icon icon="CopyIcon" />
                            <span class="align-middle ml-50">{{ i18nT(`CSV`) }}</span>
                          </b-dropdown-item>
                        </b-dropdown> -->
                    </div>
                    <!-- search input -->
                    <div class="col-12 col-md-6 col-sm 6 mb-1 pr-0">
                        <div class="custom-search d-flex justify-content-end">
                            <b-form-group>
                                <div
                                    role="group"
                                    class="input-group input-group-merge"
                                >
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <feather-icon icon="SearchIcon"/>
                                        </div>
                                    </div>
                                    <b-form-input
                                        v-model="searchQuery"
                                        :placeholder="i18nT(`Search`)"
                                        type="text"
                                        class="d-inline-block"
                                    />
                                </div>
                            </b-form-group>
                        </div>
                    </div>
                </div>
                <b-table
                    ref="refDocumentListTable"
                    :items="fetchRequests"
                    responsive
                    :fields="computedColumns"
                    primary-key="id"
                    :sort-by.sync="sortBy"
                    show-empty
                    :empty-html="emptyHtml"
                    :sort-desc.sync="isSortDirDesc"
                    class="position-relative"
                    @refreshed="onTableRefresh"
                    v-model="currentItems"
                >
                    <template v-slot:head(checkbox)="data" v-if="iCan(`timesheets`, `write`)">
                        <span>{{ data.label.toUpperCase() }}</span>
                        <b-form-checkbox
                            class="d-inline-block pl-0"
                            @change="onSelectAll"
                            v-model="selectAllStatus"
                        >
                        </b-form-checkbox>
                    </template>

                    <template #head(action)="data">
                        <span>{{ data.label.toUpperCase() }}</span>
                        <div class="sk-column-control">
                            <b-dropdown
                                no-caret
                                variant="button"
                                toggle-class="p-0"
                                :right="true"
                                class="pr-1 pl-1 d-flex sk-column-filter"
                                v-if="betaFeature"
                                v-b-tooltip = "i18nT(`Customize columns`)"
                            >
                                <template #button-content>
                                    <feather-icon
                                        icon="SettingsIcon"
                                        size="18"
                                        class="text-right"
                                    />
                                </template>

                                <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                    <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                </div>
                            </b-dropdown>
                        </div>
                    </template>

                    <template #cell(checkbox)="data">
                        <b-form-checkbox
                            v-if="iCan(`timesheets`, `write`)"
                            v-model="selectedItems"
                            :value="data.item.Id"
                        />
                    </template>

                    <template #cell(name)="data">
                        <div class="media">
                            <div class="media-aside align-self-center">
                              <b-link
                                  v-b-tooltip.hover = "i18nT(`View employee`)"
                                  :to="data.item.NotEmployee !== 1 ? {name: 'view-employee', params: { id: data.item.EmployeeUser.Id }} : null"
                                  class="font-weight-bold d-block text-nowrap"
                              >
                                <b-avatar
                                    :src="data.item.EmployeeUser.ImageUrl"
                                    :text="
                                        (data.item.EmployeeUser.FirstName
                                          ? data.item.EmployeeUser.FirstName.charAt(0)
                                          : '') +
                                        (data.item.EmployeeUser.LastName
                                          ? data.item.EmployeeUser.LastName.charAt(0)
                                          : '')
                                      "
                                    class="mr-50 badge-light-primary"
                                >
                                </b-avatar>
                              </b-link>
                            </div>
                            <div class="media-body">
                                <b-link
                                    @click="onEdit(data)"
                                    class="font-weight-bold d-block text-nowrap"
                                >
                                    {{ data.item.EmployeeUser.Label }}
                                </b-link>
                                <p class="mb-0">{{ data.item.EmployeeUser.Position }}</p>
                            </div>
                        </div>

                        <!-- <feather-icon
                          v-if="data.item.Notes"
                          icon="InfoIcon"
                          size="16"
                          class="align-middle text-body ml-1"
                          v-b-tooltip.hover.right="data.item.Notes"
                        /> -->
                    </template>

                    <template #cell(day)="data">
                        {{ data.item.Day | date }}
                    </template>

                    <template #cell(totalHours)="data">
                        {{ data.item.TotalHours }}h <span
                        v-if="data.item.TotalMinutes">{{ data.item.TotalMinutes }} min</span>
                    </template>

                    <template #cell(status)="data">
                        <span
                            :class="statusBadgeClasses(data.item)">
                          {{ getStatusTitle(data.item.ApprovalStatus) }}
                        </span>
                    </template>

                    <template #cell(paid)="data">
                        <span v-if="data.item.EmployeeUser.Type === 'contractor'"  :class="data.itemPaid ? 'text-success' : 'text-danger'">
                            <feather-icon v-if="data.item.Paid" icon="CheckIcon"  ></feather-icon>
                            <feather-icon v-else icon="XIcon"  ></feather-icon>
                            {{ data.item.Paid ? i18nT(`Yes`) : i18nT(`No`) }}
                        </span>

                        <span v-else class="text-muted">{{ i18nT(`N/A`) }}</span>
                    </template>

                    <template #cell(action)="data">
                        <b-dropdown
                            variant="link"
                            toggle-class="p-0"
                            no-caret
                            v-if="iCan(`timesheets`, `write`)"
                        >
                            <template #button-content>
                                <feather-icon
                                    icon="MoreVerticalIcon"
                                    size="16"
                                    class="align-middle text-body"
                                />
                            </template>
                            <b-dropdown-item
                                v-if="data.item.ApprovalStatus !== 1 && data.item.NotEmployee !== 1"
                                @click="approve(data)"
                            >
                                <feather-icon icon="ThumbsUpIcon"/>
                                <span
                                    class="align-middle ml-50"

                                >{{ i18nT(`Approve`) }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="data.item.ApprovalStatus !== 2 && data.item.NotEmployee !== 1"
                                @click="reject(data)"
                            >
                                <feather-icon icon="ThumbsDownIcon"/>
                                <span
                                    class="align-middle ml-50"

                                >{{ i18nT(`Reject`) }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="data.item.EmployeeUser.Type === 'contractor' && data.item.Paid === 0 && data.item.NotEmployee !== 1"
                                @click="markAsPaid(data.item, true)"
                            >
                                <feather-icon icon="DollarSignIcon"/>
                                <span
                                    class="align-middle ml-50"

                                >{{ i18nT(`Mark as paid`) }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="onEdit(data)"
                                v-if="data.item.NotEmployee !== 1"
                            >
                                <feather-icon icon="EditIcon"/>
                                <span
                                    class="align-middle ml-50"
                                >{{
                                        i18nT(`Edit`)
                                    }}</span>
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="onDelete(data)"
                            >
                                <feather-icon
                                    icon="TrashIcon"
                                    color="red"
                                />
                                <span
                                    class="align-middle ml-50"
                                    style="color: red"
                                >{{ i18nT(`Delete`) }}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </b-table>

                <div class="mx-1 mb-2">
                    <b-row>
                        <b-col
                            cols="12"
                            sm="6"
                            class="
                                d-flex
                                align-items-center
                                justify-content-center justify-content-sm-start
                              "
                        >
                            <b-form-checkbox
                                v-if="iCan(`timesheets`, `write`)"
                                class="d-inline-block mx-1"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                                {{ i18nT(`All`) }}
                            </b-form-checkbox>
                            <span class="text-muted">
                                {{ i18nT(`Showing`) }} {{ dataMeta.from }} to
                                {{ dataMeta.to }} of {{ dataMeta.of }}
                                {{ i18nT(`Entries`) }}
                            </span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalDocuments"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </b-card>
        <!-- MODAL -->
        <b-modal
            id="modal-request"
            :title="i18nT(`Timesheet`)"
            ok-only
            :ok-title="i18nT(`Save`)"
            :ok-disabled="currentTimesheet.NotEmployee === 1"
            no-close-on-backdrop
            @ok="validationRequest"
            @hidden="clearModalData"
        >
            <validation-observer ref="requestForm">
                <b-form>
                    <b-row>
                        <b-col sm="12">
                            <b-row>
                                <b-col md="12" v-if="currentTimesheet && currentTimesheet.NotEmployee !== 1">
                                    <b-form-group
                                        :label="i18nT(`Employee`)"
                                        label-for="message"
                                        class="required"
                                    >
                                      <mini-employee-card :employee-id="requestEmployee" />
                                        <validation-provider
                                            #default="{ errors }"
                                            name="To"
                                            rules="required"
                                        >
                                            <v-select
                                                v-model="requestEmployee"
                                                :options="employeeFilterOptions"
                                                :state="errors.length > 0 ? false : null"
                                                :reduce="(e) => (e.value == '' ? '' : parseInt(e.value))"
                                                :disabled="!iCan('timesheets', 'write')"
                                                label="text"
                                                :clearable="false"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="12">
                                    <b-form-group
                                        :label="i18nT(`Day`)"
                                        label-for="message"
                                        class="required"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Day"
                                            rules="required"
                                        >
                                            <date-picker
                                                reset-button
                                                v-model="requestDay"
                                                placeholder=""
                                                :state="errors.length > 0 ? false : null"
                                                :disabled="!iCan('timesheets', 'write') || currentTimesheet.NotEmployee === 1"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col md="12">
                                    <b-row
                                        v-for="(timesheetHour, idx) in timesheetHours"
                                        :key="idx"
                                    >
                                        <b-col md="10">
                                            <b-row>
                                                <b-col>
                                                    <b-form-group
                                                        label-for="message"
                                                        class="required"
                                                    >
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="From"
                                                            rules="required"
                                                        >
                                                            <b-form-timepicker
                                                                v-model="timesheetHour.FromTime"
                                                                locale='en'
                                                                :hour12="false"
                                                                :placeholder="i18nT(`From`)"
                                                                :readonly="!iCan('timesheets', 'write') || currentTimesheet.NotEmployee === 1"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                                <span class="pt-50">:</span>
                                                <b-col>
                                                    <b-form-group
                                                        label-for="message"
                                                        class="required"
                                                    >
                                                        <validation-provider
                                                            #default="{ errors }"
                                                            name="End"
                                                            rules="required"
                                                        >
                                                            <b-form-timepicker
                                                                v-model="timesheetHour.ToTime"
                                                                locale='en'
                                                                :hour12="false"
                                                                :placeholder="i18nT(`End`)"
                                                                :readonly="!iCan('timesheets', 'write') || currentTimesheet.NotEmployee === 1"
                                                            />
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                        </b-col>

                                        <b-col md="2" v-if="currentTimesheet && currentTimesheet.NotEmployee !== 1">
                                            <b-button
                                                v-if="idx === timesheetHours.length - 1 && iCan('timesheets', 'write')"
                                                variant="primary"
                                                class="btn-icon rounded-circle"
                                                @click="addNewTimesheetHour"
                                            >
                                                <feather-icon icon="PlusIcon"/>
                                            </b-button>
                                            <b-button
                                                v-else-if="iCan('timesheets', 'write')"
                                                variant="danger"
                                                class="btn-icon rounded-circle"
                                                @click="removeTimesheetHour(idx)"
                                            >
                                                <feather-icon icon="MinusIcon"/>
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col md="12">
                                    <b-form-group
                                        :label="i18nT(`Note`)"
                                        label-for="message"
                                    >
                                        <b-form-textarea
                                            :readonly="!iCan('timesheets', 'write') || !currentTimesheet || currentTimesheet.NotEmployee === 1"
                                            v-model="requestNote"/>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
        <!-- MODAL -->
        <b-modal
            id="modal-reason"
            :title="i18nT(`Reason`)"
            ok-only
            :ok-title="i18nT(`Ok`)"
            no-close-on-backdrop
            @ok="validateReason"
        >
            <validation-observer ref="reasonForm">
                <b-form>
                    <b-row>
                        <b-col sm="12">
                            <b-row class="mt-2">
                                <b-col md="12">
                                    <b-form-group
                                        :label="i18nT(`Reason`)" class="required"
                                        label-for="message"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Reason"
                                            rules="required"
                                        >
                                            <b-form-textarea
                                                v-model="reason"
                                                :state="errors.length > 0 ? false : null"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,
    // BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    // BListGroup, BListGroupItem,
    BFormCheckbox,
    BModal,
    BForm,
    BFormTextarea,
    BFormInput,
    BLink,
    VBTooltip,
    BAvatar,
    BFormTimepicker
    // VBModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useTimesheet from './useTimesheet'
import {avatarText} from '@core/utils/filter'
import {dictToSelectArray} from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import DatePicker from '@core/components/DatePicker.vue'
import useAuth from "@/auth/useAuth";
import MiniEmployeeCard from "@/views/common/Employees/MiniEmployeeCard.vue";

// import _ from 'lodash'

export default {
    components: {
        MiniEmployeeCard,
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BButton,
        BTable,
        // BLink,
        BDropdown,
        BDropdownItem,
        BPagination,
        // BListGroup,
        // BListGroupItem,
        vSelect,
        BFormCheckbox,
        BModal,
        BForm,
        BFormTextarea,
        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BLink,
        BAvatar,
        DatePicker,
        BFormTimepicker,
        // ToastificationContent
        // VBModal
    },
    directives: {
        Ripple,
        // 'b-modal': VBModal,
        'b-tooltip': VBTooltip
    },
    props: {
        extFilters: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },
    data() {
        return {
            timesheetHours: [
                {
                    FromTime: '00:00:00',
                    ToTime: '00:00:00'
                }
            ],
            pagination: {},
            selectedItems: [],
            selectedEmployee: null,
            currentItems: [],
            selectAllStatus: false,
            audienceGroups: [],
            requestHours: null,
            requestDay: null,
            requestType: null,
            requestEmployee: null,
            currentTimesheet: {},
            approvalStatusOptions: [{value: '', text: this.i18nT(`Any`)}],
            employeeFilterOptions: [{value: '', text: this.i18nT(`All`)}],
            employeeFilterOptions_form: [],
            reason: '',
            required,
            requestNote: '',
            avalableFields: [
              {label: this.i18nT(`Day`), key: 'day', visible: true},
              {label: this.i18nT(`Paid`), key: 'paid', visible: true},
              {label: this.i18nT(`Total hours`), key: 'totalHours', visible: true},
            ],
            controlVisible: true,
        }
    },
    watch: {
        requests: function (val) {
            setTimeout(() => {
                const {timesheetId} = this.$route.query
                if (timesheetId) {
                    const item = val.find(e => (e.Id === parseInt(timesheetId)))
                    this.onEdit({item})
                }
            }, 100)
        },
    },
    created() {
        this.getStatus()
        this.getEmployeeFilter()

        let storePreferences = useAuth.getColumnPreferences('timeSheets')
        if(storePreferences) {
          this.avalableFields = storePreferences
        }
        this.fetchRequests(null, this.determineVisibility);
    },
    mounted() {
        if(this.$route.query.start_date){
            this.from = this.$route.query.start_date;
            this.to = this.$route.query.end_date;
            this.refetchData();
        }
      if(document.querySelector('#btn-showModal')){
        document.querySelector('#btn-showModal').addEventListener('click', ()=> this.$bvModal.show('modal-request'))
      }


    },
    methods: {
        clearModalData() {
          this.selectedItems = []
          this.requestDay = null
          this.requestHours = null
          this.requestNote = ''
          this.requestEmployee = null
          this.currentTimesheet = {}

        },
        determineVisibility(invoiceArray) {
          if (invoiceArray.length === 0) {
            this.controlVisible = false;
          }
        },
        statusBadgeClasses(item) {
            let base_classes = 'badge badge-pill ';
            switch (item.ApprovalStatus) {
                case 0:
                    return base_classes + 'badge-light-warning';
                case 1:
                    return base_classes + 'badge-light-success';
                case 2:
                    return base_classes + 'badge-light-danger';
                default:
                    return base_classes + 'badge-light-secondary';
            }
        },
        markAsPaid(item, status) {
            let selectedIds = []
            if(item && item.Id) {
                selectedIds.push(item.Id)
            } else {
                selectedIds = this.selectedItems
            }
            this.$http.post(`timesheets/markPaid`, {
                Ids: selectedIds,
                Paid: status ? 1 : 0
            }).then(() => {
                this.$bvToast.toast(this.i18nT(`Timesheets marked as paid successfully`), {
                    title: this.i18nT(`Success`),
                    variant: 'success',
                    solid: true
                })
                this.refetchData()
            }).catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(err),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger'
                    }
                })
            })
        },
        getEmployeeFilter() {
            this.$http.get(`timesheets/employeesList`).then(({data}) => {
                this.employeeFilterOptions = dictToSelectArray(data.data)
                this.employeeFilterOptions_form = this.employeeFilterOptions.filter(
                    option => option.value
                )
            })
        },
        getStatus() {
            this.$http.get(`timesheets/approvalStatusFilter`).then(({data}) => {
                let approvals = dictToSelectArray(data.data)
                this.approvalStatusOptions = [
                    ...this.approvalStatusOptions,
                    ...approvals
                ]
                if(this.$route.query.status){
                    this.status = parseInt(this.$route.query.status);
                }
            })
        },
        addNewTimesheetHour() {
            this.timesheetHours.push({
                FromTime: this.timesheetHours[this.timesheetHours.length - 1].ToTime,
                ToTime: '00:00:00'
            })
        },
        removeTimesheetHour(idx) {
            this.timesheetHours.splice(idx, 1)
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onDelete(leave) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`timesheets?id=${leave.item.Id}`)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.refetchData()
                        })
                        .catch(ex => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(ex),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.Id)
            } else {
                this.selectedItems = []
            }
        },
        onPutInFolder(data) {
            console.log(data)
            // this.$bvModal.show('modal-pipeline');
        },
        validationRequest(event) {
            event.preventDefault()
            this.$refs.requestForm.validate().then(success => {
                if (success) {
                    //TODO
                    const formData = new FormData()
                    if (this.currentTimesheet) {
                        formData.append('id', this.currentTimesheet.Id)
                    }
                    formData.append('Day', this.requestDay)
                    // formData.append('TotalHours', 3)
                    formData.append('Notes', this.requestNote)
                    formData.append('UserId', this.requestEmployee)
                    this.timesheetHours.forEach((t, idx) => {
                        if (t.Id) formData.append(`timesheet_hours_id[${idx}]`, t.Id)
                        formData.append(
                            `timesheet_hours_from[${idx}]`,
                            t.FromTime.substr(0, 5)
                        )
                        formData.append(`timesheet_hours_to[${idx}]`, t.ToTime.substr(0, 5))
                    })
                    // formData.append('HalfDay', 0)

                    this.$http
                        .post('timesheets', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.selectedItems = []
                            this.$bvModal.hide('modal-request')
                            this.requestDay = null
                            this.requestHours = null
                            this.requestNote = ''
                            this.requestEmployee = null
                            this.currentTimesheet = {}
                            this.refetchData()

                        })
                        .catch(ex => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(ex),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        getStatusTitle(statusId) {
            try {
                return this.approvalStatusOptions.find(
                    option => option.value === statusId + ''
                ).text
            } catch (ex) {
                console.log(ex)
                return ''
            }
        },
        approve(data) {
            this.selectedEmployee=data.item.Id
            this.callApprove()
        },
        callApprove() {
            const formData = new FormData()
            formData.append('id', this.selectedEmployee ? this.selectedEmployee : this.selectedItems.join(','))
            this.$http
                .post('timesheets/approve', formData)
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.successMessage(res),
                            icon: 'InfoIcon',
                            variant: 'success'
                        }
                    })
                    this.selectedItems = []
                    this.selectedEmployee = null
                    this.selectAllStatus = false
                    this.refetchData()
                })
                .catch(ex => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: this.errorMessage(ex),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    })
                })
        },
        bulkReject() {
            this.$bvModal.show('modal-reason')
        },
        reject(data) {
            this.selectedEmployee = data.item.Id
            this.$bvModal.show('modal-reason')
        },
        validateReason(event) {
            event.preventDefault()
            this.$refs.reasonForm.validate().then(success => {
                if (success) {
                    const formData = new FormData()
                    formData.append('id', this.selectedEmployee ? this.selectedEmployee : this.selectedItems.join(','))
                    formData.append('reason', this.reason)
                    this.$http
                        .post('timesheets/reject', formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            this.$bvModal.hide('modal-reason')
                            this.selectedEmployee = null
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                        })
                        .catch(ex => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(ex),
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        },
        onEdit(data) {
            this.currentTimesheet = data.item
            this.timesheetHours = data.item.TimesheetHours.length
                ? data.item.TimesheetHours
                : [{FromTime: '', ToTime: ''}]
            this.requestDay = data.item.Day
            this.requestHours = data.item.TotalHours
            this.requestNote = data.item.Notes
            this.requestEmployee = data.item.UserId
            this.$bvModal.show('modal-request')
        },
        onExportExcel() {
        },
        onExportCsv() {
        }
    },
    setup() {
        const {
            fetchRequests,
            requests,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            from,
            to,
            type,
            employee,
            status,
            surveys,
            refetchData
        } = useTimesheet()

        return {
            fetchRequests,
            requests,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            from,
            to,
            type,
            employee,
            status,
            avatarText,
            surveys,
            refetchData
        }
    },
    computed: {
        emptyHtml() {
          return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
              + this.i18nT(`Here, you can create and manage your timesheets. No timesheets have been created yet.`) + "</h4>"
              +"<button class='btn btn-outline-secondary mt-2'  id='btn-showModal'>" + this.i18nT(`Create timesheet`) + "</button>"
        },
        computedColumns() {

            let col = [...this.tableColumns]

            for(let i = 0; i < this.avalableFields.length; i++) {
              if(!this.avalableFields[i].visible) {
                col = col.filter(c => c.key !== this.avalableFields[i].key)
              }
            }
            useAuth.setColumnPreferences('timeSheets', this.avalableFields);

            return col
      },
    },
    beforeUnmount() {
        document.querySelector('#btn-showModal').removeEventListener('click', ()=> {
            this.$bvModal.show('modal-request')
        })
    }
}
</script>

<style lang="scss">
.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';

.section-title {
    font-size: 11px;
}

.document-name {
    display: inline;
    line-height: 32px;
}

.document-actions-bar {
    float: right;
}

.ql-editor {
    min-height: 200px;
}

.tag-container {
    display: inline-block;
    background: #e6e6e6;
    color: #626262;
    padding: 3px 9px;
    font-size: 11px;
    font-weight: 600;
    margin: 4px;
    border-radius: 5px;

    .handler {
        cursor: pointer;
    }
}

.cv-card {
    min-height: 180px;

    span {
        font-size: 12px;
    }
}
</style>
