<template>
    <div>
        <b-input-group >
            <b-input-group-prepend v-if="calendarButton">
                <b-button
                    variant="primary"
                    @click.stop="inputClick"
                    class="corners-straight-right"
                >
                    <font-awesome-icon  :icon="['fas', 'calendar-alt']" />
                </b-button>
            </b-input-group-prepend>
          <b-form-input
              v-if="showInput"
              class="rounded sk-input-date-picker corners-straight-left"
              v-model="manualDate"
              :state="manualDateState"
              @change="onContext"
              :placeholder="dateFormat"
              @keydown="onlyNumber($event)"
              autocomplete="off"
          />
            <VueCtkDateTimePicker
                v-show="!showInput"
                :id="'Datepick' + refIdRandom"
                class="sk-date-picker-bar corners-straight-left"
                :min-date="min"
                :max-date="max"
                :persistent="persistent"
                :inline="inline"
                :noHeader="noHeader"
                :range="range"
                :disabled="disabled"
                :disabledWeekly="disabledWeekly"
                v-model="tempValue"
                @input="onContext"
                @is-hidden="closed"
                :only-date="onlyDate"
                :only-time="false"
                :format="`YYYY-MM-DD`"
                :formatted="dateFormat"
                :color="`#5a5aff`"
                :label="i18nT(`Select date`)"
                :button-now-translation="i18nT(`Today`)"
                :first-day-of-week="1"
                :no-button="true"
                :auto-close="true"
                :error="state || null"
                :locale="locale"
                :ref="`datepickerRef`+refIdRandom"
                :customShortcuts="customShortcuts"
            />
        </b-input-group>
    </div>
</template>

<script>
import {
    BInputGroup,
    BFormInput,
    BButton,
    BInputGroupPrepend,
    //BFormDatepicker
} from 'bootstrap-vue'
import useAuth from '@/auth/useAuth'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import moment from 'moment';

//import Debugger from '@/views/Debugger.vue'

export default {
    components: {
        BInputGroup,
        BButton,
        BFormInput,
        BInputGroupPrepend,
        //BFormDatepicker,
        //Debugger,
        VueCtkDateTimePicker
    },
    data() {
        return {
            selected: '',
            tempValue: '',
            manualDate: null,
            secondaryCheck: false,
            manualDateState: null,
            isInitContext: true,
            selectedDate: new Date(),
            refIdRandom: Math.floor(Math.random() * 20),
            customShortcuts: [
                { key: 'thisWeek', label: this.i18nT(`This week`), value: 'isoWeek' },
                { key: 'lastWeek', label: this.i18nT(`Last week`), value: '-isoWeek' },
                { key: 'last7Days', label: this.i18nT(`Last 7 days`), value: 7 },
                { key: 'last30Days', label: this.i18nT(`Last 30 days`), value: 30 },
                { key: 'thisMonth', label: this.i18nT(`This month`), value: 'month' },
                { key: 'lastMonth', label: this.i18nT(`Last month`), value: '-month' },
                { key: 'thisYear', label: this.i18nT(`This year`), value: 'year' },
                { key: 'lastYear', label: this.i18nT(`Last year`), value: '-year' }
            ],
        }
    },
    props: {
        state: {
            type: Boolean,
            required: false
        },
        manualInput:{
            type: Boolean,
            default: false
        },
        value: {
            type: [String, Object]
        },
        disabled: {
            type: Boolean,
            default: false
        },
        min:null,
        max:null,
        onlyDate: {
            type: Boolean,
            default: true
        },
        range: {
            type: Boolean,
            default: false
        },
        disabledWeekly: {
            type: Array,
            default: () => []
        },
        persistent: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        },
        noHeader: {
            type: Boolean,
            default: false
        },
        calendarButton: {
            type: Boolean,
            default: true
        }

    },
    created() {
      this.secondaryCheck = this.manualInput
    },
    watch: {
        value: function(val) {
            this.tempValue = val
            const validDate = moment(val, 'YYYY-MM-DD').format(this.dateFormat) !== 'Invalid date'
            if(this.manualInput && val && val.length === 10 && validDate) {
              this.manualDate = moment(val, 'YYYY-MM-DD').format(this.dateFormat)
            } else if(val === null) {
              this.manualDate = ''
            }
        },
        state(val) {
          if(val == false) {
            this.manualDateState = false
          } else {
            this.manualDateState = null
          }
        },
        manualDate(newVal, oldVal) {
          if (newVal === '') {
            this.tempValue = null;
            return;
          }

          const isValid = moment(newVal, this.dateFormat).format('YYYY-MM-DD') !== 'Invalid date';

          if (isValid) {
            this.manualDateState = null;
            if (newVal.length < 10) {
              this.tempValue = newVal;
            } else if (newVal.length === 10) {
              this.tempValue = moment(newVal, this.dateFormat).format('YYYY-MM-DD');
            }
          } else {
            if (newVal.at(-1) !== '0' && newVal !== '') {
              this.manualDateState = false;
            }
        }

        const checkForForwardSlash = newVal.match(/\//g) ? newVal.match(/\//g).length < 2 : true;
        const isLengthIncrease = oldVal ? newVal.length > oldVal.length : false;
        const isSlashNotAtEnd = newVal.slice(-1) !== '/';

        if ((this.dateFormat === 'DD/MM/YYYY' || this.dateFormat === 'MM/DD/YYYY') && checkForForwardSlash) {
          if ((newVal.length === 2 || newVal.length === 5) && isLengthIncrease && isSlashNotAtEnd) {
            this.manualDate = `${newVal}/`;
          } else if ((newVal.length === 3 || newVal.length === 6) && isLengthIncrease && isSlashNotAtEnd) {
            this.manualDate = newVal.slice(0, -1) + '/' + newVal.slice(-1);
          }
        } else if (checkForForwardSlash) {
          if ((newVal.length === 4 || newVal.length === 7) && isLengthIncrease && isSlashNotAtEnd) {
            this.manualDate = `${newVal}/`;
          } else if ((newVal.length === 5 || newVal.length === 8) && isLengthIncrease && isSlashNotAtEnd) {
            this.manualDate = newVal.slice(0, -1) + '/' + newVal.slice(-1);
          }
        }
      },
    },
    mounted() {
        if (this.value) this.tempValue = this.value
    },
    methods: {
        onlyNumber($event) {
          const input = $event.target;
          const textSelected = input.selectionStart !== input.selectionEnd;

          if (!/^\d$/.test($event.key) && $event.key !== 'Backspace') {
            $event.preventDefault();
          } else if (this.manualDate.length >= 10 && $event.key !== 'Backspace' && !textSelected ) {
            $event.preventDefault();
          }
        },
        inputClick() {

          if(this.secondaryCheck) {
            this.secondaryCheck = false
          }

          const inputElement = this.$refs[`datepickerRef`+this.refIdRandom].$el.querySelector('input');
          console.log("CLICKING REF: ", `datepickerRef`+this.refIdRandom)
          if (inputElement) {
              inputElement.click();
          }
        },
        closed() {
          this.secondaryCheck = true
        },
        onContext() {
            if (this.tempValue) {
                this.$emit('input', this.tempValue)
            } else {
              this.$emit('input', null)
            }
        }
    },
    computed: {
        locale() {
            return useAuth.getLocale() !== 'undefined'
                ? useAuth.getLocale()
                : 'en'
        },
        dateFormat() {
            return useAuth.getDateFormat() !== 'undefined'
                ? useAuth.getDateFormat()
                : 'DD/MM/YYYY'
        },
        showInput() {
          if (this.manualInput && this.secondaryCheck) {
            return true;
          } else {
            return false;
          }

        }
    }
}
</script>

<style>

div.date-time-picker {
    width: calc(100% - 56px);
}


.date-time-picker input.field-input {
    padding-right: 5px;
}

.ctk-date-time-picker__header,
.datetimepicker .custom-button,
.datetimepicker span.custom-button-content,
.datepicker-buttons-container .datepicker-button.now .datepicker-button-content
{
    /* Change the header text color */
    color: #5a5aff !important;
    fill: #5a5aff !important;
}
.sk-date-picker-bar {
  z-index: 5;
}
.sk-input-date-picker {
  z-index: 10;
}


</style>
